.bg-gradient {
    background-image: linear-gradient(to bottom right, $palette-black-0, $palette-grey-700);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
}

.registration-page {
    background-color: $palette-grey-200;
    color: $palette-grey-700;
    min-height: 100vh;
    position: relative;

    // background-image:url('https://gcdn.pbrd.co/images/aupUVcsDl4An.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    a {
        color: $palette-blue-0;
    }

    .container-view {
        padding: 0;
    }
    .card-body {
        padding: 2.5rem;
        .cardLabel {
            color: #6a7178;
            margin-bottom: 0.625rem;
        }
    }
    .page-left-container {
        padding-top: 12.5rem;
        padding-bottom: 12.5rem;
        padding-right: 1.5rem;
        .lead {
            color: $palette-grey-700;
            font-weight: 400;
            font-size: $text-xl;
            line-height: 1.3;
        }
    }
    .page-right-container {
        padding-top: 5rem;
        padding-bottom: 10rem;
        color: $palette-black-0;
    }
    .page-title {
        color: $palette-blue-0;
        font-weight: 700;
        font-size: $text-xxl;
        margin-bottom: 1.2rem;
    }
    p.card-text {
        font-size: $text-base-size;
    }

    .form-control {
        // box-shadow: none !important;
    }
    a.red {
        color: $link-primary-color !important;
    }
    .form-instruction {
        font-size: $text-base-size;
    }
    // .has-error .form-feedback-item{
    //   font-size: $text-sm;
    // }
    .green-circle,
    .grey-circle,
    .orange-circle {
        background: #74b816;
        width: 16px;
        height: 16px;
        border-radius: 10px;
        display: block;
        float: left;
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;
    }
    .green-circle {
        background: #74b816;
    }
    .grey-circle {
        background: #4f575e;
    }
    .orange-circle {
        background: #e9a100;
    }
    .green-circle.type2,
    .grey-circle.type2,
    .orange-circle.type2 {
        width: 20px;
        height: 20px;
    }
    .grey-circle.type2 {
        background: #ced4da;
    }
    .password-tooltip {
        position: absolute;
        background-color: $palette-grey-700;
        padding: 18px 16px;
        border-radius: 4px;
        width: 244px;
        font-size: 13px;
        left: -254px;
        top: -20px;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                position: relative;
                padding: 0 0 0 24px;
                color: #adb5bd;
                margin-bottom: 8px;
            }
        }
        .grey-circle,
        .green-circle {
            top: 2px;
        }
        .arrow-right {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 9px solid $palette-grey-700;
            position: absolute;
            right: -9px;
            top: 60px;
        }

        .arrow-top {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 9px solid #272b30;
            position: absolute;
            top: -9px;
        }
    }

    .tooltip {
        &.clay-tooltip-top {
            .arrow {
                left: 90%;
            }
        }
        max-width: 300px;
        .tooltip-inner {
            text-align: left;
            padding: 10px ​16px;
            max-width: 300px;
        }
    }

    .step-page.step4,
    .step-page.step7 {
        .list-group-item {
            padding-top: 0.55rem;
            padding-bottom: 0.55rem;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            margin-bottom: 0.65rem;
            border-radius: 4px;
            border: 1px solid $palette-grey-300;
        }
        .cards-title {
            font-size: 18px;
            margin-bottom: 1.2rem;
        }
    }

    .step-page.step5 {
        .panel-header {
            color: $palette-grey-700;
        }
        .autofit-col small {
            color: $palette-grey-600;
        }
        .form-col {
            .form-group {
                margin-bottom: 0.5rem;
            }
        }
        input[type="text"] {
            border: 1px solid #6a7178;
        }
    }

    .step-page.step7 {
        .green-circle,
        .grey-circle,
        .orange-circle {
            // position: relative;
        }
    }

    //step5 page
    .card.card1 {
        border-radius: 0.25rem 0.25rem 0 0;
        margin: 0;
        border-bottom: none;
    }
    .card.card2 {
        border-radius: 0 0 0.25rem 0.25rem;
        border-top: none;
        background-color: #f1f3f5;
    }
    .card.card3 {
        margin-bottom: 16px;
        border: none;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        .card3Title {
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
        .card3Body {
            padding: 1rem;
            font-weight: 400;
        }
    }
    .li-checkbox {
        margin-bottom: 6px;
        border: none;
        border-radius: 0.2rem;
    }
    .li-field {
        flex-shrink: inherit;
    }

    //Profile builder
    [role="tablist"] {
        &.panel {
            margin-bottom: 6px;
            border: none;
        }
        .panel-header-link {
            padding: 0;
            border: none;
            background: none;
            cursor: default;
            box-shadow: none !important;
        }
        .panel-body {
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            padding-bottom: 1rem;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            .gutter-col {
                display: flex;
                flex-direction: column;
                flex: 0.145;
                flex-basis: 100%;
            }
            .form-col {
                display: flex;
                flex-direction: column;
                flex: 1;
                flex-basis: 100%;
                justify-content: flex-start;
                padding-left: 0.625rem;
                padding-right: 0.625rem;
            }
        }
        .card .card-text {
            font-size: medium;
        }
    }
    .step4 {
        .form-feedback-item {
            color: $palette-grey-500;
        }
    }
}

.supportSiteIdrow {
    align-items: center;
}
.broadcomSoftwareType {
    margin-left: 10px;
    align-items: center;
}

.err {
    color: #cd2031;
}

small {
    font-size: 0.8rem;
}
